import { render, staticRenderFns } from "./Sys_settings.vue?vue&type=template&id=323b0ddb&scoped=true&"
import script from "./Sys_settings.vue?vue&type=script&lang=js&"
export * from "./Sys_settings.vue?vue&type=script&lang=js&"
import style0 from "./Sys_settings.vue?vue&type=style&index=0&id=323b0ddb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323b0ddb",
  null
  
)

export default component.exports